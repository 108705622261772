.iconBar {
  display: flex;
  justify-content: space-around;
  /* border: 1px solid #555555; */
  color: #dfdfdf;
  font-size: 30px;
  width: 100%;
  /* padding: 10px; */
}

.icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  /* border-left: 1px solid #555555;
  border-top: 1px solid #555555;
  border-bottom: 1px solid #555555; */
  width: 100%;
}
.icon:last-child {
  /* border-right: 1px solid #555555; */
}
