.collapsedCard {
  padding: 0px;
}

.openCard {
  border-radius: 4px;
}

.buttonBar {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  bottom: 0;
}

.devices {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  flex-wrap: wrap;
}

.siderBody {
  display: "flex";
  flex-direction: "column";
  justify-content: "space-end";
}

.post-header {
  min-height: calc(100vh - 6rem);
}

/* .contentWrapper,
.sider {
  height: calc(100vh);
}
@supports (height: 100svh) {
  .contentWrapper,
  .sider {
    height: calc(100svh+3rem);
  }
} */
