.bubble {
  display: block;
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 10px;
}

.bubble:hover:after {
  background-color: red;
}
.bubble.online:hover:after {
  background-color: green;
}

.bubble:after {
  content: "";
  background-color: red;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  display: block;
  top: 1px;
  left: 1px;
}
.bubble.online:after {
  background-color: green;
}

.bubble .bubble-outer-dot {
  margin: 1px;
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(255, 0, 0, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite;
}
.bubble .bubble-outer-dot.online {
  background-color: green;
}

.bubble .bubble-inner-dot {
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(255, 0, 0, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite;
}
.bubble .bubble-inner-dot.online {
  background-color: green;
}

.bubble .bubble-inner-dot:after {
  content: "";
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(255, 0, 0, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite;
}
.bubble .bubble-inner-dot.online:after {
  background-color: green;
}

@-webkit-keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@-moz-keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@-o-keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

/*Center-div (Not part of the symbol)*/

#center-div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 14px;
  height: 14px;
}

.online {
  color: green !important;
}
