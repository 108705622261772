.loginWrapper {
  max-width: 500px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 10px 5px 15px #eee;
  vertical-align: middle;
}

.centerWrap {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
